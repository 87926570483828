import React from 'react';
import { useExecutive } from '../api/getExecutive';
import { useParams } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';

const OverviewHeader = (): JSX.Element => {
    const { executiveId } = useParams<string>();
    if (!executiveId) return <></>;
    const { executive } = useExecutive(executiveId);

    if (!executive) {
        return <></>;
    }
    return (
        <>
            <h1>{executive.name}</h1>
            {executive.summary ? (
                <div>
                    <p>{executive.summary} </p>
                    <TooltipProvider delayDuration={100}>
                        <Tooltip>
                            <TooltipTrigger className="text-black rounded px-2 py-1 text-sm bg-cometrics-100">
                                <i className="far fa-sparkles text-cometrics mr-2"></i>
                                Generated by CLAIR
                            </TooltipTrigger>
                            <TooltipContent className="w-96 border-cometrics">
                                <p>
                                    This Executive Summary was created using the latest updates from the
                                    executive&apos;s social media activity, LinkedIn posts, and references within
                                    corporate documents, including press releases, sustainability reports, company
                                    websites, and Analyst Calls.
                                </p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
            ) : (
                <p>Biography being curated. Please check back later or contact us</p>
            )}
        </>
    );
};

export default OverviewHeader;
