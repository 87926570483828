import React from 'react';
import SkeletonCard from '~/components/skeletonCard';
import { Card, CardContent, CardDescription, CardHeader } from '~/components/ui/card';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';

import { useExecutive } from '../api/getExecutive';
import { useParams } from 'react-router-dom';

const ThumbnailAndBio = (): JSX.Element => {
    const { executiveId } = useParams<string>();
    if (!executiveId) return <></>;
    const { executive, isLoading } = useExecutive(executiveId);

    if (isLoading) {
        return <SkeletonCard />;
    }
    if (!executive) {
        return <></>;
    }
    return (
        <Card className="border-0 shadow-none">
            <CardHeader className="pt-0">
                <img
                    alt="Executive Thumbnail"
                    src={executive.profile_thumbnail_url}
                    className="bg-white rounded-xl w-full max-w-[150px] object-cover aspect-square"
                />
            </CardHeader>
            <CardContent>
                <h2>{executive.name}</h2>
                <CardDescription>
                    {executive.title} @ {executive.company.name}
                </CardDescription>
                <p>{executive.biography}</p>
                {executive?.jaccard?.rank && executive?.jaccard?.rank !== 0 ? (
                    <TooltipProvider delayDuration={100}>
                        <Tooltip>
                            <TooltipTrigger className="text-black rounded mt-3 px-2 py-1 text-sm bg-cometrics-100">
                                <i className="far fa-chart-line text-cometrics mr-2"></i>
                                {executive.jaccard.rank}/10 Corporate Comms Alignment
                            </TooltipTrigger>
                            <TooltipContent className="w-96 border-cometrics">
                                <p>
                                    We use a Jaccardian index to calculate how aligned Executive communications are with
                                    Corporate communications.
                                </p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                ) : null}
            </CardContent>
        </Card>
    );
};

export default ThumbnailAndBio;
